<template>
  <div>
    <button class="btn btn-primary btn-rounded btn-md">Preparar Proposta</button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

div{
    text-align: center;    
    /* margin-bottom: 20px; */
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1;
    

}
button {
  /* background: red; */
  width: 100%;
  padding: 10px 20px;
}
</style>