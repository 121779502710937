<template>
  <section id="medicos">
    <FiltrosAvancados/>

    <div class="row spaceMapa">
      <b-skeleton-wrapper :loading="loading" class="col-lg-12">
        <template #loading>
          <div class="row" style="height: 97% ">
            <div class="col-lg-12 " >
              <b-skeleton height="" size="100%"  ></b-skeleton>
              <!-- height 400px -->
            </div>
            <div class="col-lg-3 d-none " >
              <b-skeleton height="" size="100%" ></b-skeleton>
              <!-- height 400px -->

            </div>
          </div>
        </template>

        <div class="col-lg-9 grid-margin stretch-card mapaItem">
          <b-card>
            <GChart
              :settings="{ packages: ['geochart'] }"
              type="GeoChart"
              :data="chartData"
              :options="chartOptions"
              class="mapaItem1"
            />
            <Proposta />
          </b-card>
        </div>
      </b-skeleton-wrapper>
    </div>

    <div class="col-md-3 grid-margin stretch-card medicos">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-row p-3">
            <div class="align-self-top">
              <p class="card-title mb-1 font-weight-bold">Médicos</p>

              <h3 class="mb-0">{{ totalRows | numberFilter }}</h3>
            </div>
            <div class="align-self-center flex-grow text-right">
              <i class="icon-lg mdi mdi-account-outline text-success"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="preview-list">
                <div
                  v-for="uf in totalUfs.slice(0, 5)"
                  :key="uf.uf"
                  class="preview-item border-bottom py-3"
                >
                  <div class="preview-item-content d-sm-flex flex-grow">
                    <div class="flex-grow">
                      <h6 class="preview-subject">{{ uf.estado.nome }}</h6>
                    </div>
                    <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                      <p class="text-muted">
                        {{ uf.uf_count | numberFilter }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MedicoService from "@/services/doktor/medico.service";
import FiltrosAvancados from "../../../components/doktor/FiltrosAvancados.vue";
import { GChart } from "vue-google-charts";
import Proposta from "../../../components/doktor/Proposta.vue";

export default {
  name: "MedicoSearch",
  components: {
    GChart,
    FiltrosAvancados,
    Proposta,
  },
  data() {
    return {
      totalRows: 0,
      totalUfs: [],
      loading: true,
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: null,
      
      chartOptions: {
        region: "BR",
        resolution: "provinces",
        colorAxis: {
          colors: ["#acb2b9", "#2f3f4f"],
        },
        backgroundColor: "#81d4fa",
        keepAspectRatio: true,
      },
    };
  },
  computed: {
    ...mapState(["medicoFiltros"]),
    ...mapGetters(["medicoFiltrosQuery"]),
  },
  mounted() {
    this.search();
  },
  watch: {
    // Atualizar contador somente se houver mudancas nos filtros
    medicoFiltrosQuery: function () {
      this.search();
    },
  },
  methods: {
    search() {
      let params = {
        ...this.medicoFiltrosQuery,
      };
      this.loading = true;
      MedicoService.total(params).then((response) => {
        this.totalRows = response.data.total;
        this.chartData = [["Estado", "Médicos"]];
        this.totalUfs = response.data.ufs;
        response.data.ufs.forEach((uf) => {
          this.chartData.push([uf.estado.nome, uf.uf_count]);
        });

        this.loading = false;
      });
    },
  },
  created: function () {
    // `this` aponta para a instância
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;

  @media (max-width: 768px) {
    align-items: center;
  }
}

.mapaItem {
  min-width: 100%;
}

.medicos {
  padding-right: 0;
}
.spaceMapa {
  width: 100%;
  flex-wrap: nowrap;
  margin: 0;
  .mapaItem1 {
    height: 100%;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    .mapaItem {
      min-width: 100% !important;
      height: 50%;
      order: 2;
    }
    .mapaItem1 {
      height: 100%;
    }
    .medicos {
      order: 1;
      min-width: 100%;
      border-radius: 5px;
    }
  }
}

@media (max-width: 992px) {
  section {
    flex-direction: column;
  }
  .medicos {
    min-width: 100% !important;
    padding: 0;
  }
  .mapaItem {
    padding: 0;
  }
}



@media (max-width: 320px) {
  .medicos {
    padding: 0;
  }

}
</style>
