import axios from 'axios';
import authHeader from '../auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/doktor/areaAtuacaos";

class AreaAtuacaoService {

    getAll() {
        return axios.get(API_URL, { headers: authHeader() });
    }
}

export default new AreaAtuacaoService();
